exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-policies-tsx": () => import("./../../../src/pages/policies.tsx" /* webpackChunkName: "component---src-pages-policies-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-repositories-tsx": () => import("./../../../src/pages/repositories.tsx" /* webpackChunkName: "component---src-pages-repositories-tsx" */),
  "component---src-pages-settings-account-info-tsx": () => import("./../../../src/pages/settings/account-info.tsx" /* webpackChunkName: "component---src-pages-settings-account-info-tsx" */),
  "component---src-pages-settings-compliance-controls-tsx": () => import("./../../../src/pages/settings/compliance-controls.tsx" /* webpackChunkName: "component---src-pages-settings-compliance-controls-tsx" */),
  "component---src-pages-settings-compliance-directives-tsx": () => import("./../../../src/pages/settings/compliance-directives.tsx" /* webpackChunkName: "component---src-pages-settings-compliance-directives-tsx" */),
  "component---src-pages-settings-compliance-frameworks-tsx": () => import("./../../../src/pages/settings/compliance-frameworks.tsx" /* webpackChunkName: "component---src-pages-settings-compliance-frameworks-tsx" */),
  "component---src-pages-settings-functionary-stores-tsx": () => import("./../../../src/pages/settings/functionary-stores.tsx" /* webpackChunkName: "component---src-pages-settings-functionary-stores-tsx" */),
  "component---src-pages-settings-organizations-tsx": () => import("./../../../src/pages/settings/organizations.tsx" /* webpackChunkName: "component---src-pages-settings-organizations-tsx" */),
  "component---src-pages-settings-teams-tsx": () => import("./../../../src/pages/settings/teams.tsx" /* webpackChunkName: "component---src-pages-settings-teams-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-settings-users-tsx": () => import("./../../../src/pages/settings/users.tsx" /* webpackChunkName: "component---src-pages-settings-users-tsx" */),
  "component---src-pages-silent-renew-tsx": () => import("./../../../src/pages/silent-renew.tsx" /* webpackChunkName: "component---src-pages-silent-renew-tsx" */),
  "component---src-pages-sources-tsx": () => import("./../../../src/pages/sources.tsx" /* webpackChunkName: "component---src-pages-sources-tsx" */),
  "component---src-pages-verifications-tsx": () => import("./../../../src/pages/verifications.tsx" /* webpackChunkName: "component---src-pages-verifications-tsx" */),
  "component---src-templates-markdown-pages-tsx": () => import("./../../../src/templates/MarkdownPages.tsx" /* webpackChunkName: "component---src-templates-markdown-pages-tsx" */)
}

